<template>
  <div class="content-wrapper">

    <ErrorModal
      :modalShow="modalError.showModal"
      :caseModal="modalError.caseModal"
      @confirmed="redirecionarLista"
    />

    <b-card no-body>
      <b-overlay :show="loadingShow" rounded="lg" opacity="0.6" class="w-100">
        <EmpresaHeader :empresa="empresa" @confirmed="resolveRoute"/>
        <main class="p-2">
          <alertaTodasAbas :situacao="situacaoCadastro" :show="situacaoCadastro == 'Incompleto'" />

          <div class="contatoPrincipalEmpresa">
            <div class="mb-2 label_contato" cols="12">Contato Principal</div>
            <FormShowEmpresaContato
              :contatoEmpresa.sync="contatoPrincipal"
            />
          </div>

          <div class="mt-3 contatosEnderecosEmpresa" v-for="(contatoEndereco, index) in dadosContatoEndereco" :key="index">
            <div class="mb-2 label_contato" cols="12">Contato - Endereco {{ index + 1 }}</div>
            <FormShowEmpresaContato
              :contatoEmpresa="contatoEndereco"
            />
          </div>

          <section v-if="canEdit" class="buttons mt-3">
            <b-button id="redireciona-edicao" 
                @click.prevent="redirectToEdit()"
                variant="custom-blue"
            >
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar</span>
            </b-button>
          </section>
        </main>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BCard, BTab, BTabs, BButton, BFormGroup, BOverlay } from 'bootstrap-vue'
import EmpresaHeader from '@/views/components/custom/page-header/Header-empresa.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import alertaTodasAbas from '@/views/components/custom/timed-alert/alertaAbasIncompletasFechavel.vue'
import { getIdEmpresa } from '@core/utils/store/getStore';
import { setIdEmpresa } from '@core/utils/store/setStore';
import FormShowEmpresaContato from '../forms/FormShowEmpresaContato.vue';

export default {

  title: 'Detalhes empresa',

  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    BFormGroup,
    EmpresaHeader,
    ErrorModal,
    alertaTodasAbas,
    BOverlay,
    FormShowEmpresaContato
  },

  data() {
    return {
      situacaoCadastro: 'Completo',
      loadingShow: true,
      canEdit: false,
      modalError: {
        showModal: false,
        caseModal: 'idInexistente'
      },
      contatoPrincipal: {
        nome: "-",
        tipoContatoDescricao: "-",
        email: "-",
        telefone: null,
        telefone_secundario: null
      },
      dadosContatoEndereco: [],
      empresa: {
        id_empresa: '',
        nome: '',
        gerenciarColaboradores: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false
      }
    }
  },

  mounted() {
    if((getIdEmpresa() === null || typeof getIdEmpresa() == 'undefined') && typeof this.$route.params.idEmpresa == "undefined") {
        this.openModalError();
        return;
    }

    if(typeof this.$route.params.idEmpresa != "undefined"){
        if (this.$route.params.idEmpresa !== getIdEmpresa()) {
            setIdEmpresa(this.$route.params.idEmpresa);
        }
    }

    this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
      this.setDadosParaVisualizar(data)
    })
    this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
  },

  methods: {
    redirectToEdit() {
      this.$router.push(
        { 
          name: 'empresa-edit', 
          params: {
              idEmpresa: this.empresa.id_empresa
          }
        }
      );
    },

    openModalError() {
      this.modalError.showModal = true;
    },

    setDadosParaVisualizar(dados){
      this.situacaoCadastro = dados['situacao_cadastro']
      this.empresa.id_empresa = getIdEmpresa();
      this.contatoPrincipal.nome = dados['responsavel'] ? dados['responsavel'] : "-"
      this.contatoPrincipal.tipoContatoDescricao = dados['tipo_contato_descricao'] ? dados['tipo_contato_descricao'] : "-"
      this.contatoPrincipal.email = dados['email'] ? dados['email'] : "-"
      this.contatoPrincipal.telefone = dados['telefone'] ? dados['telefone'] : "-"
      this.contatoPrincipal.telefone_secundario = dados['telefone_secundario'] ? dados['telefone_secundario'] : "-"
      this.empresa.nome = dados['cnpj'] ? dados['nome_fantasia'] : dados['nome']
      this.empresa.gerenciarColaboradores = dados['pode_gerenciar_colaboradores']
      this.loadingShow = false

      dados.enderecos.map(endereco => {
        this.dadosContatoEndereco.push({
          nome: endereco.contato.nome ?? "-",
          tipoContatoDescricao: endereco.contato.cargo ?? "-",
          email: endereco.contato.email ?? "-",
          telefone: endereco.contato.telefone ?? "-",
          telefone_secundario: endereco.contato.telefone_secundario ?? "-",
          endereco: {
            logradouro: endereco.logradouro,
            numero: endereco.numero,
            bairro: endereco.bairro,
            cidade: endereco.cidadeNome,
            estado: endereco.estadoNome,
            cep: endereco.cep,
          }
        }); 
      });
    },

    redirecionarLista() {
      this.$router.push({ name: 'empresa-list' });
    },

    resolveRoute(route){
      if(route != this.$route.name){
        this.$router.push(
          { 
              name: route, 
              params: {
                empresa: this.empresa.id_empresa
              }
          }
        );
      }
    },

    verifyPermissions(action) {
          const permissionUO = this.$can(actions[action], subjects.EMPRESAS_UO)
          const permissionDR = this.$can(actions[action], subjects.EMPRESAS_DR)
          const permissionAR = this.$can(actions[action], subjects.EMPRESAS_AR)

          return [permissionUO, permissionDR, permissionAR].includes(true);
    }
  }
}
</script>

<style scoped>
  .label_contato {
      font-size: 16px;
      font-weight: 900;
  }
</style>
